<template>
	<div class="AboutSongDetail">
		<w-navTab titleText="协会会歌"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="header">上饶义警进行曲</div>
			<div class="body">
				<!-- <div class="body-audio">
					<audio controls="controls">
						<source src="@/assets/audio/mp.mp3">
					</audio>
				</div> -->
				<div class="body-video">
					<video src="@/assets/audio/sryj.mp4" controls autoplay></video>
				</div>
				<div class="body-img">
					<img src="@/assets/audio/sryj.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NewsApi from '@/api/news'
import UtilsHelper from '@/vendor/utils';
import LoadingPage from '@/components/LoadingPage';
import PlayerVendor from '@/vendor/player';
import Vant from '@/vendor/vant';
	
export default {
	name: 'AboutSongDetail',
	data() {
		return {
			loadingPage: true,
		};
	},
	created() {
		this.loadingPage = false
	},
	destroyed() {
		
	},
	methods: {
		showPopup() {
			Vant.ImagePreview({
				images: ['http://image.srww.cn/shangRaoShiYiJingXiaoChengXu/sryj.png'],
				startPosition: 0
			});
		},
	},
	components: {
		LoadingPage
	}
};
</script>

<style scoped lang="less">
	.AboutSongDetail{
		width: 100%;
		min-height: 100vh;
		background-color: #FFFFFF;
		padding-bottom: 20px;
		box-sizing: border-box;
		
		.container{
			.header{
				padding: 30px 0 24px;
				font-size: 22px;
				font-weight: bold;
				line-height: 36px;
				text-align: center;
				color: #333;
				box-sizing: border-box;
			}
			.body{
				padding: 0 16px;
				box-sizing: border-box;
				
				.body-audio{
					audio{
						width: 343px;
					}
				}
				.body-video{
					// margin-top: 20px;
					
					video{
						width: 343px;
					}
				}
				.body-img{
					margin-top: 20px;
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}
</style>