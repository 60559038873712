import Global from '@/vendor/global'

const development = {
	apiUrl: 'http://192.168.124.18:8085', // 接口地址
	// apiUrl: 'http://192.168.124.24:6559', // 接口地址
	
	// imWsUrl: 'ws://192.168.124.24:6558', // 聊天websocket地址
	imWsUrl: 'ws://192.168.124.18:6558', // 聊天websocket地址
	
	// imApiUrl: 'http://192.168.124.24:6557', // 聊天接口地址
	imApiUrl: 'http://192.168.124.18:6557', // 聊天接口地址
	
	loginUrl: 'http://192.168.124.18:8085/api/index/wxLogin', // 登录地址
	// loginUrl: 'http://192.168.124.24:6559/api/index/wxLogin', // 登录地址
	
	shareUrl: 'http://192.168.124.18:8085/api/index/share?url=', // 分享第三方链接地址
	shareLogoUrl: 'https://srsyjweb.jxwwkj.cn/logo.jpg', // 分享logo地址
	iframeUrl:'https://srsyjwx.jxwwkj.cn/index.php/index/we_chat/iframe?url=',
	circleFriends: 'http://192.168.124.23:8080/#/circleDetail/', //义警圈分享地址
	defaultDistrict: { // 默认区域ID
		id: 1,
		type: 1,
		name: '上饶市',
	},
	district: {
		// 上饶市
		1: {
			column: {
				about: 14, // 协会介绍
				bylaws: 16, // 协会章程
				emblem: 21, // 会徽释义
				talent: 107, // 协会文件
				news: '5,3,21,28,35,42,49,56,63,70,77,84,91,98,105',
				page: [{
					id: '5,3,21,28,35,42,49,56,63,70,77,84,91,98,105',
					title: '协会动态'
				},{
					// id: '10,4,20,27,34,41,48,55,62,69,76,83,90,97,104',
					id: '10',
					title: '协会公告'
				},{
					// id: '11,12,22,29,36,43,50,57,64,71,78,85,92,99,106',
					id: '11',
					title: '重大活动'
				}],
			},
			ad: {
				banner: 1,
				center: 3
			}
		},
		
		// 信州区
		2: {
			column: {
				about: 26, // 协会介绍
				bylaws: 27, // 协会章程
				emblem: 28, // 会徽释义
				talent: 109, // 协会文件
				news: '3,4,12', // 首页
				page: [{ // 内页
					id: 3,
					title: '协会动态'
				}, {
					id: 4,
					title: '协会公告'
				}, {
					id: 12,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 4,
			}
		},
		
		// 广丰区
		3: {
			column: {
				about: 31, // 协会介绍
				bylaws: 32, // 协会章程
				emblem: 33, // 会徽释义
				talent: 118, // 协会文件
				news: '21,20,22', // 首页
				page: [{ // 内页
					id: 21,
					title: '协会动态'
				}, {
					id: 20,
					title: '协会公告'
				}, {
					id: 22,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 5,
			}
		},

		// 广信区
		4: {
			column: {
				about: 35, // 协会介绍
				bylaws: 36, // 协会章程
				emblem: 37, // 会徽释义
				talent: 119, // 协会文件
				news: '28,27,29', // 首页
				page: [{ // 内页
					id: 28,
					title: '协会动态'
				}, {
					id: 27,
					title: '协会公告'
				}, {
					id: 29,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 6,
			}
		},
		
		// 玉山县
		5: {
			column: {
				about: 38, // 协会介绍
				bylaws: 39, // 协会章程
				emblem: 40, // 会徽释义
				talent: 120, // 协会文件
				news: '35,34,36', // 首页
				page: [{ // 内页
					id: 35,
					title: '协会动态'
				}, {
					id: 34,
					title: '协会公告'
				}, {
					id: 36,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 7,
			}
		},

		// 铅山县
		6: {
			column: {
				about: 41, // 协会介绍
				bylaws: 42, // 协会章程
				emblem: 43, // 会徽释义
				talent: 123, // 协会文件
				news: '42,41,43', // 首页
				page: [{ // 内页
					id: 42,
					title: '协会动态'
				}, {
					id: 41,
					title: '协会公告'
				}, {
					id: 43,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 8,
			}
		},

		// 横峰县
		7: {
			column: {
				about: 44, // 协会介绍
				bylaws: 45, // 协会章程
				emblem: 46, // 会徽释义
				talent: 121, // 协会文件
				news: '49,48,50', // 首页
				page: [{ // 内页
					id: 49,
					title: '协会动态'
				}, {
					id: 48,
					title: '协会公告'
				}, {
					id: 50,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 9,
			}
		},

		// 弋阳县
		8: {
			column: {
				about: 47, // 协会介绍
				bylaws: 48, // 协会章程
				emblem: 49, // 会徽释义
				talent: 122, // 协会文件
				news: '56,55,57', // 首页
				page: [{ // 内页
					id: 56,
					title: '协会动态'
				}, {
					id: 55,
					title: '协会公告'
				}, {
					id: 57,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 10,
			}
		},

		// 余干县
		9: {
			column: {
				about: 52, // 协会介绍
				bylaws: 51, // 协会章程
				emblem: 50, // 会徽释义
				talent: 116, // 协会文件
				news: '63,62,64', // 首页
				page: [{ // 内页
					id: 63,
					title: '协会动态'
				}, {
					id: 62,
					title: '协会公告'
				}, {
					id: 64,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 11,
			}
		},

		// 鄱阳县
		10: {
			column: {
				about: 53, // 协会介绍
				bylaws: 56, // 协会章程
				emblem: 55, // 会徽释义
				talent: 126, // 协会文件
				news: '70,69,71', // 首页
				page: [{ // 内页
					id: 70,
					title: '协会动态'
				}, {
					id: 69,
					title: '协会公告'
				}, {
					id: 71,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 12,
			}
		},

		// 万年县
		11: {
			column: {
				about: 57, // 协会介绍
				bylaws: 54, // 协会章程
				emblem: 58, // 会徽释义
				talent: 125, // 协会文件
				news: '77,76,78', // 首页
				page: [{ // 内页
					id: 77,
					title: '协会动态'
				}, {
					id: 76,
					title: '协会公告'
				}, {
					id: 78,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 13,
			}
		},

		// 婺源县
		12: {
			column: {
				about: 59, // 协会介绍
				bylaws: 60, // 协会章程
				emblem: 61, // 会徽释义
				talent: 112, // 协会文件
				news: '84,83,85', // 首页
				page: [{ // 内页
					id: 84,
					title: '协会动态'
				}, {
					id: 83,
					title: '协会公告'
				}, {
					id: 85,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 14,
			}
		},
		// 德兴市
		13: {
			column: {
				about: 62, // 协会介绍
				bylaws: 63, // 协会章程
				emblem: 64, // 会徽释义
				talent: 124, // 协会文件
				news: '91,90,92', // 首页
				page: [{ // 内页
					id: 91,
					title: '协会动态'
				}, {
					id: 90,
					title: '协会公告'
				}, {
					id: 92,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 15,
			}
		},

		// 三清山
		14: {
			column: {
				about: 65, // 协会介绍
				bylaws: 66, // 协会章程
				emblem: 67, // 会徽释义
				talent: 128, // 协会文件
				news: '98,97,99', // 首页
				page: [{ // 内页
					id: 98,
					title: '协会动态'
				}, {
					id: 97,
					title: '协会公告'
				}, {
					id: 99,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 16,
			}
		},

		// 经开区
		15: {
			column: {
				about: 34, // 协会介绍
				bylaws: 68, // 协会章程
				emblem: 69, // 会徽释义
				talent: 127, // 协会文件
				news: '105,104,106', // 首页
				page: [{ // 内页
					id: 105,
					title: '协会动态'
				}, {
					id: 104,
					title: '协会公告'
				}, {
					id: 106,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 17,
			}
		}
	}
}


const production = {
	apiUrl: 'https://srsyjapi.jxwwkj.cn',  // 接口地址
	imWsUrl: 'wss://srsyjmsg.jxwwkj.cn',    // 聊天websocket地址
	imApiUrl: 'https://srsyjupd.jxwwkj.cn', // 聊天接口地址
	loginUrl: 'https://srsyjapi.jxwwkj.cn/api/index/wxLogin', // 登录地址
	shareUrl: 'https://srsyjapi.jxwwkj.cn/api/index/share?url=', // 分享第三方链接地址
	shareLogoUrl: 'https://srsyjweb.jxwwkj.cn/logo.jpg', // 分享logo地址
	iframeUrl:'https://srsyjwx.jxwwkj.cn/index.php/index/we_chat/iframe?url=',
	circleFriends: 'https://srsyjwx.jxwwkj.cn/circleDetail/', //义警圈分享地址
	defaultDistrict: { // 默认区域ID 
		id: 1,
		type: 1,
		name: '上饶市',
	},
	district: {
		// 上饶市
		1: {
			column: {
				about: 14, // 协会介绍
				bylaws: 16, // 协会章程
				emblem: 21, // 会徽释义
				talent: 107, // 协会文件
				news: '5,3,21,28,35,42,49,56,63,70,77,84,91,98,105',
				page: [{
					id: '5,3,21,28,35,42,49,56,63,70,77,84,91,98,105',
					title: '协会动态'
				}, {
					// id: '10,4,20,27,34,41,48,55,62,69,76,83,90,97,104',
					id: '10',
					title: '协会公告'
				}, {
					// id: '11,12,22,29,36,43,50,57,64,71,78,85,92,99,106',
					id: '11',
					title: '重大活动'
				}],
			},
			ad: {
				banner: 1,
				center: 3
			}
		},
		
		// 信州区
		2: {
			column: {
				about: 26, // 协会介绍
				bylaws: 27, // 协会章程
				emblem: 28, // 会徽释义
				talent: 117, // 协会文件
				news: '3,4,12', // 首页
				page: [{ // 内页
					id: 3,
					title: '协会动态'
				}, {
					id: 4,
					title: '协会公告'
				}, {
					id: 12,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 4,
			}
		},
		
		// 广丰区
		3: {
			column: {
				about: 31, // 协会介绍
				bylaws: 32, // 协会章程
				emblem: 33, // 会徽释义
				talent: 118, // 协会文件
				news: '21,20,22', // 首页
				page: [{ // 内页
					id: 21,
					title: '协会动态'
				}, {
					id: 20,
					title: '协会公告'
				}, {
					id: 22,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 5,
			}
		},

		// 广信区
		4: {
			column: {
				about: 35, // 协会介绍
				bylaws: 36, // 协会章程
				emblem: 37, // 会徽释义
				talent: 119, // 协会文件
				news: '28,27,29', // 首页
				page: [{ // 内页
					id: 28,
					title: '协会动态'
				}, {
					id: 27,
					title: '协会公告'
				}, {
					id: 29,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 6,
			}
		},
		
		// 玉山县
		5: {
			column: {
				about: 38, // 协会介绍
				bylaws: 39, // 协会章程
				emblem: 40, // 会徽释义
				talent: 120, // 协会文件
				news: '35,34,36', // 首页
				page: [{ // 内页
					id: 35,
					title: '协会动态'
				}, {
					id: 34,
					title: '协会公告'
				}, {
					id: 36,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 7,
			}
		},

		// 铅山县
		6: {
			column: {
				about: 41, // 协会介绍
				bylaws: 42, // 协会章程
				emblem: 43, // 会徽释义
				talent: 123, // 协会文件
				news: '42,41,43', // 首页
				page: [{ // 内页
					id: 42,
					title: '协会动态'
				}, {
					id: 41,
					title: '协会公告'
				}, {
					id: 43,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 8,
			}
		},

		// 横峰县
		7: {
			column: {
				about: 44, // 协会介绍
				bylaws: 45, // 协会章程
				emblem: 46, // 会徽释义
				talent: 121, // 协会文件
				news: '49,48,50', // 首页
				page: [{ // 内页
					id: 49,
					title: '协会动态'
				}, {
					id: 48,
					title: '协会公告'
				}, {
					id: 50,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 9,
			}
		},

		// 弋阳县
		8: {
			column: {
				about: 47, // 协会介绍
				bylaws: 48, // 协会章程
				emblem: 49, // 会徽释义
				talent: 122, // 协会文件
				news: '56,55,57', // 首页
				page: [{ // 内页
					id: 56,
					title: '协会动态'
				}, {
					id: 55,
					title: '协会公告'
				}, {
					id: 57,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 10,
			}
		},

		// 余干县
		9: {
			column: {
				about: 52, // 协会介绍
				bylaws: 51, // 协会章程
				emblem: 50, // 会徽释义
				talent: 116, // 协会文件
				news: '63,62,64', // 首页
				page: [{ // 内页
					id: 63,
					title: '协会动态'
				}, {
					id: 62,
					title: '协会公告'
				}, {
					id: 64,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 11,
			}
		},

		// 鄱阳县
		10: {
			column: {
				about: 53, // 协会介绍
				bylaws: 56, // 协会章程
				emblem: 55, // 会徽释义
				talent: 126, // 协会文件
				news: '70,69,71', // 首页
				page: [{ // 内页
					id: 70,
					title: '协会动态'
				}, {
					id: 69,
					title: '协会公告'
				}, {
					id: 71,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 12,
			}
		},

		// 万年县
		11: {
			column: {
				about: 57, // 协会介绍
				bylaws: 54, // 协会章程
				emblem: 58, // 会徽释义
				talent: 125, // 协会文件
				news: '77,76,78', // 首页
				page: [{ // 内页
					id: 77,
					title: '协会动态'
				}, {
					id: 76,
					title: '协会公告'
				}, {
					id: 78,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 13,
			}
		},

		// 婺源县
		12: {
			column: {
				about: 59, // 协会介绍
				bylaws: 60, // 协会章程
				emblem: 61, // 会徽释义
				talent: 111, // 协会文件
				news: '84,83,85', // 首页
				page: [{ // 内页
					id: 84,
					title: '协会动态'
				}, {
					id: 83,
					title: '协会公告'
				}, {
					id: 85,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 14,
			}
		},
		// 德兴市
		13: {
			column: {
				about: 62, // 协会介绍
				bylaws: 63, // 协会章程
				emblem: 64, // 会徽释义
				talent: 124, // 协会文件
				news: '91,90,92', // 首页
				page: [{ // 内页
					id: 91,
					title: '协会动态'
				}, {
					id: 90,
					title: '协会公告'
				}, {
					id: 92,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 15,
			}
		},

		// 三清山
		14: {
			column: {
				about: 65, // 协会介绍
				bylaws: 66, // 协会章程
				emblem: 67, // 会徽释义
				talent: 128, // 协会文件
				news: '98,97,99', // 首页
				page: [{ // 内页
					id: 98,
					title: '协会动态'
				}, {
					id: 97,
					title: '协会公告'
				}, {
					id: 99,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 16,
			}
		},

		// 经开区
		15: {
			column: {
				about: 34, // 协会介绍
				bylaws: 68, // 协会章程
				emblem: 69, // 会徽释义
				talent: 127, // 协会文件
				news: '105,104,106', // 首页
				page: [{ // 内页
					id: 105,
					title: '协会动态'
				}, {
					id: 104,
					title: '协会公告'
				}, {
					id: 106,
					title: '重大活动'
				}],
			},
			ad: {
				banner: 17,
			}
		}
	}
}

export default Global.isDev ? development : production