<template>
	<div class="AboutSong">
		<div class="song-list">
			<div class="song-list-item" v-for="(item,index) in 1" :key="index" @click="$router.push({ name: 'AboutSongDetail', params:{id: 1}})">
				<div class="list-item-img">
					<img src="@/assets/img/icon65.png" alt="" />
				</div>
				<div class="list-item-right">
					<div class="item-right-title">上饶义警进行曲</div>
					<div class="item-right-bottom">
						<div class="right-bottom-title">词：程建平，曲：叶玉光</div>
						<div class="right-bottom-icon">
							<van-icon name="arrow" color="#999" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NewsApi from '@/api/news'
import UtilsHelper from '@/vendor/utils';

export default {
	name: 'AboutSong',
	data() {
		return {
			
		};
	},
	created() {
		
	}
};
</script>

<style scoped lang="less">
	.AboutSong{
		width: 100%;
		min-height: 100vh;
		background-color: #FFFFFF;
		
		.song-list{
			// padding: 0 0 0;
			box-sizing: border-box;
			
			.song-list-item{
				display: flex;
				padding: 16px 0;
				border-bottom: 1px solid #F4F4F4;
				box-sizing: border-box;
				
				.list-item-img{
					width: 58px;
					min-width: 58px;
					height: 58px;
					margin-right: 8px;
					
					img{
						width: 100%;
						height: 100%;
						border-radius: 4px;
						object-fit: cover;
					}
				}
				.list-item-right{
					flex: 1;
					
					.item-right-title{
						font-size: 18px;
						line-height: 27px;
						color: #333;
					}
					.item-right-bottom{
						margin-top: 8px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						
						.right-bottom-title{
							width: 270px;
							min-width: 270px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							font-size: 15px;
							line-height: 23px;
							color: #999;
						}
						.right-bottom-icon{
							
						}
					}
				}
			}
		}
	}
</style>