import Config from "@/vendor/config"
import Request from "@/api/request"
import Store from "@/store/store"

const login = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/login', params)
}

const sendText = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/sendText', params)
}

const sendImage = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/sendImage', params)
}

const sendVoice = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/sendVoice', params)
}

const sendRevoke = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/sendRevoke', params)
}

const enterGroup = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/enterGroup', params)
}

const leaveGroup = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/leaveGroup', params)
}

const getHistory = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/getHistory', params)
}

const getGroupDetail = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/getDetail', params)
}

const getGroup = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'server/getGroup', params)
}

// 刷新群组，配合Vuex
const refreshGroup = () => {
	return new Promise((resolve, reject) => {
		getGroup().then(result => {
			Store.commit('group/setGroup', result.data);
			resolve(result);
		}).catch(error => {
			console.log("群组刷新失败: " + error);
			reject(error)
		})
	})
}



// 新聊天接口


// 登陆
const chatLogin = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/login', params)
}

// 获取群组列表信息
const chatGetGroup = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/getGroup', params)
}

// 获取群组详情
const chatGetDetail = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/getDetail', params)
}

// 获取历史聊天记录
const chatGetHistory = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/getHistory', params)
}

// 进入群组
const chatEnterGroup = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/enterGroup', params)
}

// 离开群组
const chatLeaveGroup = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/leaveGroup', params)
}

// 消息撤回
const chatSendRevoke = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/sendRevoke', params)
}

// 发送图片
const chatSendImage = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/sendImage', params)
}

// 发送文本
const chatSendText = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/sendText', params)
}

// 发送语音
const chatSendVoice = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/sendVoice', params)
}

// 群组成员
const chatMembers = (params) => {
	return Request.setBaseUrl(Config.imApiUrl).request(Request.post, 'chat/members', params)
}


export default {
	login,
	getGroup,
	sendText,
	sendImage,
	sendVoice,
	sendRevoke,
	enterGroup,
	leaveGroup,
	getHistory,
	getGroupDetail,
	refreshGroup,
	
	
	
	chatLogin,
	chatGetGroup,
	chatGetDetail,
	chatGetHistory,
	chatEnterGroup,
	chatLeaveGroup,
	chatSendRevoke,
	chatSendImage,
	chatSendText,
	chatSendVoice,
	chatMembers
}