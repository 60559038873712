<template>
	<div class="group-chat">
		<div class="group-chat-header">
			<div class="group-chat-header-container">
				<div class="group-chat-header-left">
					<div class="group-chat-header-back" @click="$router.back()"></div>
				</div>
				<div class="group-chat-header-center">
					<div class="group-chat-header-title">{{ groupDetail.title }}</div>
					<div class="group-chat-header-sub" @click="isShowNotice = !isShowNotice"
						v-show="groupDetail.notice">
						<div class="group-chat-header-sub-text">群公告</div>
						<van-icon :name="isShowNotice ? 'arrow-up' : 'arrow-down'" size="5px" />
					</div>
				</div>
				<div class="group-chat-header-right">
					<div class="group-chat-header-detail" @click="toDetail"></div>
				</div>
			</div>
			<div class="group-chat-header-notice" v-show="isShowNotice">
				<div class="group-chat-header-notice-content">
					<div class="group-chat-header-notice-text">{{ groupDetail.notice }}</div>
				</div>
			</div>
		</div>

		<scroll-view class="group-chat-scroll" @click="onClickGroupChat">
			<div class="group-chat-header-space"></div>
			<div class="group-chat-header-space-notice" v-show="isShowNotice"></div>
			<div class="group-chat-message">
				<div class="group-chat-loading" v-show="finished == false">
					<van-loading size="24px">加载中</van-loading>
				</div>
				<div class="group-chat-finished" v-show="loading == false && finished == true">
					<div class="group-chat-finished-text">已经到顶啦</div>
				</div>
				<template v-for="(vo, key) in messageList">
					<!-- 消息时间 -->
					<div class="group-chat-message-system" v-if="vo.timeEquation">
						<div class="group-chat-message-system-time">{{ vo.timeEquation }}</div>
					</div>
					<!-- 系统通知 -->
					<div class="group-chat-message-system" v-if="vo.type == 'revoke' || vo.type == 'quit'">
						<div class="group-chat-message-system-notice">{{ vo.content }}</div>
					</div>
					<!-- 文本消息 -->
					<div v-if="vo.type == 'text'" :class="[
							'group-chat-message-item',
							{ 'group-chat-message-right': vo.user_id == userInfo.id }
						]">
						<div class="group-chat-message-item-avatar"><img :src="vo.avatar" /></div>
						<div class="group-chat-message-item-content">
							<div class="group-chat-message-item-nickname">{{ vo.name }}</div>
							<div class="group-chat-message-item-box">
								<div class="group-chat-message-item-text" @touchstart="onStartRevoke(key, vo)"
									@touchend="onFinishRevoke(key, vo)">
									{{ vo.content }}
								</div>
							</div>
						</div>
					</div>
					<!-- 图片消息 -->
					<div v-if="vo.type == 'image'" :class="[
							'group-chat-message-item',
							{ 'group-chat-message-right': vo.user_id == userInfo.id }
						]">
						<div class="group-chat-message-item-avatar"><img :src="vo.avatar" /></div>
						<div class="group-chat-message-item-content">
							<div class="group-chat-message-item-nickname">{{ vo.name }}</div>
							<div class="group-chat-message-item-box">
								<div class="group-chat-message-item-image" @touchstart="onStartRevoke(key, vo)"
									@touchend="onFinishRevoke(key, vo)">
									<img :src="vo.content" @click="onImagePreview(vo.content)" />
								</div>
							</div>
						</div>
					</div>

					<!-- 语音消息 -->
					<div class="group-chat-message-item" v-if="vo.type == 'voice'" :class="[
							'group-chat-message-item',
							{ 'group-chat-message-right': vo.user_id == userInfo.id }
						]">
						<div class="group-chat-message-item-avatar"><img :src="vo.avatar" /></div>
						<div class="group-chat-message-item-content">
							<div class="group-chat-message-item-nickname">{{ vo.name }}</div>
							<div class="group-chat-message-item-box">
								<div @touchstart="onStartRevoke(key, vo)" @touchend="onFinishRevoke(key, vo)">
									<div class="group-chat-message-item-voice group-chat-message-item-voice-play"
										v-if="playerId == vo.id" @click="onStopPlay(vo)">
										<div class="group-chat-message-item-voice-text">播放中</div>
									</div>
									<div class="group-chat-message-item-voice" v-else @click="onStartPlay(vo)">
										<div class="group-chat-message-item-voice-text">
											点击播放
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="group-chat-footer-space"></div>
			<div class="group-chat-emoji-space" v-show="isShowEmoji == true"></div>
		</scroll-view>

		<div class="group-chat-input">
			<div class="group-chat-input-container">
				<div class="group-chat-input-left" @click="isShowVoice = !isShowVoice">
					<div class="group-chat-input-left-voice" v-show="isShowVoice == false">
						<img class="group-chat-input-left-icon" :src="require('@/assets/img/icon26.png')" />
					</div>
					<div class="group-chat-input-left-keyboard" v-show="isShowVoice == true">
						<img class="group-chat-input-left-icon" :src="require('@/assets/img/icon27.png')" />
					</div>
				</div>
				<div class="group-chat-input-center">
					<div class="group-chat-input-center-voice" v-show="isShowVoice == true">
						<button @touchstart="startRecord" @touchend="finishRecord" :class="[
								'group-chat-input-center-voice-button',
								{
									'group-chat-input-center-voice-button-active':
										isRecording == true
								}
							]">
							{{ isRecording == true ? '松开发送' : '按住说话' }}
						</button>
					</div>
					<div class="group-chat-input-center-keyboard" v-show="isShowVoice == false">
						<van-field v-model="message" rows="1" :autosize="{ maxHeight: 100, minHeight: 26 }"
							type="textarea" @focus="onFocus" placeholder="请输入消息内容" />
					</div>
				</div>
				<!-- 输入区右侧按钮 -->
				<div class="group-chat-input-right">
					<div class="group-chat-input-right-phiz">
						<img v-show="isShowVoice == false" @click.prevent="isShowEmoji = !isShowEmoji"
							class="group-chat-input-left-icon" :src="require('@/assets/img/icon28.png')" />
					</div>
					<div class="group-chat-input-right-photo" v-show="!message">
						<van-uploader :after-read="onSendImage">
							<img class="group-chat-input-left-icon" :src="require('@/assets/img/icon29.png')" />
						</van-uploader>
					</div>
					<div class="group-chat-input-right-send" v-show="message">
						<div class="group-chat-input-right-send-button" @click="onSendText">
							发送
						</div>
					</div>
				</div>
			</div>
			<!-- 表情 -->
			<div class="group-chat-input-emoji" v-show="isShowEmoji == true">
				<div class="group-chat-input-emoji-container">
					<div class="group-chat-input-emoji-cell" v-for="vo in emojiList">
						<div class="group-chat-input-emoji-item" v-for="v in vo" @click="onSelectEmoji(v)">
							{{ v }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import EmojiVendor from '@/vendor/emoji';
	import ServerApi from '@/api/server.js';
	import UploadApi from '@/api/upload.js';
	import VantVendor from '@/vendor/vant.js';
	import scrollMiXin from '@/mixins/scroll';
	import ScrollView from '@/components/ScrollView';
	import MomentVendor from '@/vendor/moment';
	import WechatVendor from '@/vendor/wechat';
	import PlayerVendor from '@/vendor/player';
	import DistrictHelper from '@/vendor/district';

	export default {
		name: 'CityGroupChat',
		data() {
			return {
				player: null,
				playerId: 0,
				isShowEmoji: false,
				isRecording: false,
				isShowVoice: false,
				isShowNotice: false,
				emojiList: EmojiVendor,
				message: '',
				messageList: [],
				loading: true,
				finished: false,
				scrollView: '.group-chat-scroll',
				groupDetail: '',
				groupId: 0,
				class_type: 1,
				historyPage: 1,
				historyLimit: 20,
				lastMessageTime: '',
				userInfo: {},
				revokeTimer: null,
				district_id: '',
				department_id: 0,
				detailId: ''
			};
		},
		created() {
			this.detailId = this.$route.params.id;
			if (this.$route.params.class_type == 2) {
				this.groupId = this.$route.params.id;
			} else {
				this.department_id = this.$route.params.id
			}
			this.class_type = this.$route.params.class_type
			this.userInfo = this.$store.state.user.userInfo;
			this.district_id = this.$store.state.user.userInfo.district_id;
			let playerParams = {
				finish: this.onPlayFinish
			};

			this.onEnter();
			this.onLoad();
			this.getGroupDetail();
			this.player = new PlayerVendor(playerParams);
			this.wechatRecord = new WechatVendor.record();

			socketIo.addListener('onMessage', 'groupChat', this.onMessage);
		},
		mounted() {
			window.addEventListener('scroll', this.onScroll, true);
		},
		destroyed() {
			this.onLeave();
			this.onStopPlay();
			this.$store.commit('group/clearUnreadByGroupId', this.groupId);
			socketIo.removeListener('onMessage', 'groupChat');
			window.removeEventListener('scroll', this.onScroll, true);
		},
		methods: {
			toDetail(){
				if(this.class_type == 2){
					this.$router.push({
						name: 'GroupChatDetail',
						params: {
							id: this.detailId
						}
					});
				}else{
					this.$router.push({
						name: 'GroupChatUser',
						params: {
							id: this.detailId,
							district_id: this.district_id,
							type: this.class_type
						}
					});
				}
			},
			onMessage(data) {
				if (data.class_type == 1) {
					if (data.department_id != this.groupDetail.id) {
						return false
					}
				} else {
					if (data.group_id != this.groupDetail.id) {
						return false
					}
				}

				let prevSendTime = new Date();
				let prevMessage = this.messageList[this.messageList.length - 1];

				if (prevMessage) {
					prevSendTime = prevMessage.create_time;
				}

				if (data.type == 'revoke') {
					let index = this.messageList.findIndex(e => {
						return e.id == data.revoke_id;
					});

					if (index !== -1) {
						this.messageList.splice(index, 1);
					}
				}

				data.timeEquation = this.getTimeEquation(data.create_time, prevSendTime);
				this.messageList.push(data);

				if (data.type != 'revoke') {
					this.scrollToBottom();
				}
			},
			getTimeEquation(sendTime, prevSendTime) {
				let diff = Math.abs(MomentVendor(sendTime).diff(MomentVendor(prevSendTime), 'minute'));

				if (diff <= 2) {
					return false;
				}

				if (
					diff <= 1440 &&
					MomentVendor(sendTime).format('YYYY-MM-DD') == MomentVendor().format('YYYY-MM-DD')
				) {
					return MomentVendor(sendTime).format('a hh:mm');
				}
				return MomentVendor(sendTime).format('YYYY-MM-DD HH:mm');
			},
			getGroupDetail() {
				let params = {
					class_type: this.class_type,
					group_id: this.groupId,
					department_id: this.department_id
				};

				ServerApi.chatGetDetail(params)
					.then(result => {
						this.groupDetail = result.data;
					})
					.catch(error => {
						console.log(error);
					});
			},
			onLoad() {
				this.loading = true;

				let params = {
					page: this.historyPage,
					limit: this.historyLimit,
					class_type: this.class_type,
					group_id: this.groupId,
					department_id: this.department_id
				};

				ServerApi.chatGetHistory(params)
					.then(result => {
						this.historyPage += 1;

						let prevMessage = '';
						let timeEquation = '';
						let prevSendTime = new Date();
						let groupList = result.data.list.reverse();
						let originScrollHeight = document.querySelector(this.scrollView).scrollHeight;
						let originScrollTop = document.querySelector(this.scrollView).scrollTop;
						let clientHeight = document.querySelector(this.scrollView).clientHeight;

						for (let i = groupList.length - 1; i >= 0; i--) {
							prevMessage = groupList[i - 1];

							if (prevMessage) {
								prevSendTime = prevMessage.create_time;
							}

							groupList[i].timeEquation = this.getTimeEquation(
								groupList[i].create_time,
								prevSendTime
							);

							this.messageList.unshift(groupList[i]);
						}

						// 加载完成
						this.loading = false;

						if (this.messageList.length >= result.data.total) {
							this.finished = true;
						}

						// 防止滚动条跳转
						this.$nextTick(() => {
							let scrollHeight = document.querySelector(this.scrollView).scrollHeight;

							// 首次加载判断
							if (originScrollHeight == clientHeight) {
								document.querySelector(this.scrollView).scrollTop = scrollHeight;
							} else {
								document.querySelector(this.scrollView).scrollTop =
									scrollHeight - originScrollHeight + originScrollTop;
							}
						});
					})
					.catch(error => {
						console.error(error);
					});
			},
			onScroll() {
				if (this.isScrollToTop() && this.loading == false && this.finished == false) {
					this.onLoad();
				}
			},
			scrollToBottom() {
				this.$nextTick(() => {
					document.querySelector(this.scrollView).scrollTop = document.querySelector(
						this.scrollView
					).scrollHeight;
				});
			},
			isScrollToTop() {
				return document.querySelector(this.scrollView).scrollTop <= 100;
			},
			onImagePreview(url) {
				VantVendor.ImagePreview({
					images: [url],
					closeable: true
				});
			},
			onEnter() {
				let params = {
					client_id: client_id,
					class_type: this.class_type,
					group_id: this.groupId,
					department_id: this.department_id
				};

				ServerApi.chatEnterGroup(params)
					.then(result => {
						console.log(result);
					})
					.catch(error => {
						console.log(error);
					});
			},
			onFocus() {
				this.isShowEmoji = false;
			},
			onLeave() {
				let params = {
					client_id: client_id,
					class_type: this.class_type,
					group_id: this.groupId,
					department_id: this.department_id
				};
				ServerApi.chatLeaveGroup(params)
					.then(result => {
						console.log(result);
					})
					.catch(error => {
						console.log(error);
					});
			},
			onClickGroupChat() {
				this.isShowEmoji = false;
			},
			startRecord() {
				this.isRecording = true;
				this.wechatRecord
					.startRecord()
					.then(result => {
						VantVendor.Toast({
							message: '录音中',
							duration: 0,
							icon: 'audio',
							transition: 'none'
						});
					})
					.catch(error => {
						console.log(error);
						VantVendor.Toast.fail(error.errMsg);
					});
			},
			finishRecord() {
				this.isRecording = false;
				this.wechatRecord
					.stopRecord()
					.then(voice => {
						VantVendor.Toast.loading({
							message: '发送中',
							loadingType: 'spinner',
							transition: 'none',
							forbidClick: true,
							duration: 0
						});

						UploadApi.voice(voice.localId)
							.then(result => {
								return ServerApi.chatSendVoice({
									content: result.data.filePath,
									class_type: this.class_type,
									group_id: this.groupId,
									department_id: this.department_id
								});
							})
							.catch(error => {
								console.error(error);
							})
							.finally(result => {
								VantVendor.Toast.clear();
							});
					})
					.catch(error => {
						console.log(error);
						VantVendor.Toast.fail(error.errMsg);
					});
			},
			onSelectEmoji(emoji) {
				this.message += emoji;
			},
			onSendImage(image) {
				UploadApi.image(image.file)
					.then(result => {
						return ServerApi.chatSendImage({
							content: result.data.filePath,
							class_type: this.class_type,
							group_id: this.groupId,
							department_id: this.department_id
						});
					})
					.then(result => {
						console.log(result);
					})
					.catch(error => {
						console.log(error);
					});
			},
			onSendText() {
				let params = {
					content: this.message,
					class_type: this.class_type,
					group_id: this.groupId,
					department_id: this.department_id
				};

				ServerApi.chatSendText(params)
					.then(result => {
						this.message = '';
					})
					.catch(error => {
						console.log(error);
					});
			},
			onStartPlay(data) {
				this.playerId = data.id;
				this.player.play(data.content);
			},
			onStopPlay(data) {
				this.playerId = 0;
				this.player.finish();
			},
			onPlayFinish() {
				this.playerId = 0;
			},
			onStartRevoke(key, data) {
				if (data.user_id == this.userInfo.id) {
					this.revokeTimer = setTimeout(() => {
						let params = {
							title: '提示',
							message: '确认撤回该消息吗？'
						};

						VantVendor.Dialog.confirm(params)
							.then(result => {
								ServerApi.chatSendRevoke({
										revoke_id: data.id,
										class_type: this.class_type,
										group_id: this.groupId,
										department_id: this.department_id
									})
									.then(result => {
										VantVendor.Toast.success({
											message: '撤回成功'
										});
									})
									.catch(error => {
										VantVendor.Toast.fail({
											message: error.msg
										});
									});
							})
							.catch(error => {
								VantVendor.Toast('已取消');
							});
					}, 500);
				}
			},
			onFinishRevoke() {
				clearTimeout(this.revokeTimer);
			}
		},
		components: {
			ScrollView
		}
	};
</script>

<style lang="less" scoped>
	.group-chat {
		.group-chat-scroll {
			height: 100vh;
		}

		.group-chat-header-space {
			height: 56px;
		}

		.group-chat-header-space-notice {
			height: 72px;
		}

		.group-chat-footer-space {
			height: 48px;
		}

		.group-chat-emoji-space {
			height: 220px;
		}

		//  加载等待
		.group-chat-loading {
			padding: 0 0 15px;

			.van-loading {
				text-align: center;
			}
		}

		.group-chat-finished {
			padding: 0 0 15px;
			text-align: center;

			.group-chat-finished-text {
				font-size: 14px;
				color: #969799;
			}
		}

		.group-chat-header {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 10;

			.group-chat-header-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: #fff;
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
				position: relative;
				z-index: 5;

				.group-chat-header-center {
					flex: 1;
					text-align: center;

					.group-chat-header-title {
						font-size: 16px;
						line-height: 24px;
						font-weight: bold;
						color: #333;
						margin-top: 5px;
					}

					.group-chat-header-text {
						font-size: 12px;
						line-height: 16px;
						color: #666;
					}

					.group-chat-header-sub {
						display: flex;
						flex: 1;
						align-items: center;
						justify-content: center;

						.group-chat-header-sub-text {
							font-size: 12px;
							line-height: 16px;
							color: #666;
							padding-right: 5px;
						}
					}
				}

				.group-chat-header-back {
					width: 9px;
					height: 56px;
					background-image: url('../../assets/img/icon23.png');
					background-repeat: no-repeat;
					background-position: center;
					background-size: 9px;
					padding-right: 20px;
					padding-left: 13px;
				}

				.group-chat-header-right {
					width: 42px;
				}

				.group-chat-header-detail {
					width: 25px;
					height: 56px;
					background-image: url('../../assets/img/icon24.png');
					background-repeat: no-repeat;
					background-position: center;
					background-size: 25px;
					padding-right: 12px;
					padding-left: 5px;
					// box-sizing: border-box;
				}
			}

			.group-chat-header-notice {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				transform: translateY(100%);
				background-color: #fff;
				padding: 10px;
				box-sizing: border-box;

				.group-chat-header-notice-content {
					min-height: 32px;
					padding: 10px;
					background-color: #f2f2f2;
					border-radius: 6px;

					.group-chat-header-notice-text {
						font-size: 12px;
						line-height: 16px;
						color: #666;
						word-break: break-all;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
				}
			}
		}

		.group-chat-message {
			padding: 15px 10px 0;

			.group-chat-message-item {
				display: flex;
				margin-bottom: 15px;

				.group-chat-message-item-avatar {
					padding-right: 10px;

					img {
						width: 48px;
						height: 48px;
						object-fit: cover;
						border-radius: 100%;
					}
				}

				.group-chat-message-item-content {
					flex: 1;
					display: flex;
					flex-wrap: wrap;
					padding-right: 50px;
					justify-content: left;

					.group-chat-message-item-nickname {
						font-size: 13px;
						line-height: 24px;
						color: #666;
						width: 100%;
						text-align: left;
					}

					.group-chat-message-item-box {
						width: 100%;

						.group-chat-message-item-text {
							float: left;
							padding: 12px 15px;
							background-color: #fff;
							border-radius: 6px;
							font-size: 18px;
							color: #333;
							word-break: break-all;
							text-align: left;
						}

						.group-chat-message-item-image {
							float: left;

							img {
								height: 140px;
								max-width: 100%;
								object-fit: cover;
								border-radius: 6px;
							}
						}

						.group-chat-message-item-voice {
							float: left;
							display: flex;
							align-items: center;
							background-color: #fff;
							padding: 10px;
							border-radius: 6px;
							width: 70px;
							padding-left: 42px;
							background-image: url('~@/assets/img/icon25.png');
							background-position: 10px center;
							background-repeat: no-repeat;
							background-size: 30px;

							&.group-chat-message-item-voice-play {
								background-image: url('~@/assets/img/icon30.png');
							}

							.group-chat-message-item-voice-text {
								padding-left: 5px;
								font-size: 16px;
								color: #333;
							}

							img {
								width: 32px;
							}
						}
					}
				}

				&.group-chat-message-right {
					flex-direction: row-reverse;
					justify-content: center;

					.group-chat-message-item-content {
						padding-right: 0;
						padding-left: 50px;
						justify-content: right;

						.group-chat-message-item-nickname {
							text-align: right;
						}

						.group-chat-message-item-box {
							text-align: right;

							.group-chat-message-item-text {
								float: right;
								background-color: #d7ecff;
							}

							.group-chat-message-item-image {
								float: right;
							}

							.group-chat-message-item-voice {
								float: right;
								background-color: #d7ecff;
							}
						}
					}

					.group-chat-message-item-avatar {
						padding-right: 0;
						padding-left: 10px;
					}
				}
			}

			.group-chat-message-system {
				text-align: center;
				margin-bottom: 15px;

				.group-chat-message-system-time {
					color: #666;
					font-size: 12px;
					line-height: 16px;
				}

				.group-chat-message-system-notice {
					color: #666;
					font-size: 12px;
					line-height: 24px;
				}
			}
		}

		.group-chat-input {
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100%;
			background-color: #fff;
			padding: 8px 10px;
			box-sizing: border-box;

			.group-chat-input-container {
				display: flex;

				.group-chat-input-left-icon {
					width: 32px;
					display: block;
				}

				.group-chat-input-center {
					flex: 1;
					padding: 0 10px;

					.group-chat-input-center-keyboard {
						.van-field {
							padding: 3px 10px;
							background-color: #eee;
							border-radius: 6px;
							font-size: 16px;
						}
					}

					.group-chat-input-center-voice {
						.group-chat-input-center-voice-button {
							width: 100%;
							height: 32px;
							background-color: #eee;
							color: #333;
							margin: 0;
							padding: 0;
							border: none;
							border-radius: 6px;
							font-size: 16px;
							line-height: 32px;
							user-select: none;

							&.group-chat-input-center-voice-button-active {
								color: #fff;
								background: #f43e3d;
							}
						}
					}
				}

				.group-chat-input-right {
					display: flex;
					font-size: 0;

					.group-chat-input-right-phiz {
						margin-right: 10px;
					}

					.group-chat-input-right-send {
						.group-chat-input-right-send-button {
							width: 56px;
							height: 32px;
							border-radius: 6px;
							background-color: #f43e3d;
							color: #fff;
							text-align: center;
							line-height: 32px;
							font-size: 16px;
						}
					}
				}
			}

			.group-chat-input-emoji {
				padding: 10px 0;

				.group-chat-input-emoji-container {
					height: 200px;
					overflow-y: scroll;
					-webkit-overflow-scrolling: touch;

					.group-chat-input-emoji-cell {
						display: flex;

						.group-chat-input-emoji-item {
							flex: 1;
							font-size: 24px;
							text-align: center;
							line-height: 40px;
						}
					}
				}
			}
		}
	}
</style>