<template>
	<div :class="['group-chat-user', { color: active == 1 }]">
		<w-navTab titleText="部门详情"></w-navTab>
		<div class="head-top">
			<div class="head-top-title">{{ groupTitle }}</div>
			<div class="head-top-text">共{{ groupTotalUser }}人</div>
		</div>
		<div class="head-nav">
			<w-tab :active="active" :data="loveData" @click="onClick"></w-tab>
		</div>

		<div class="head" v-if="active == 0">
			<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="head-middle">
					<div class="head-middle-item" v-for="(item, index) in grouplist" v-if="item.duty">
						<div class="item-picture"><img :src="item.avatar" alt="" /></div>
						<div class="item-right">
							<div class="item-right-title">{{item.name}}</div>
							<div class="item-right-text">职务：{{ item.duty }}</div>
						</div>
					</div>
				</div>
				<div class="heda-conner">
					<div class="head-conner-item" v-for="vo in grouplist" v-if="vo.duty == ''">
						<div class="conner-item-picture"><img :src="vo.avatar" alt="" /></div>
						<div class="conner-item-text">{{testuserName(vo.name)}}</div>
					</div>
				</div>
			</VantList>
		</div>
		<div v-if="active == 1">
			<GroupChatRank :groupId="groupId" :district_id="district_id"></GroupChatRank>
		</div>

	</div>
</template>

<script>
	import WTab from '@/components/Tab';
	import Vant from '@/vendor/vant';
	import GroupChatRank from '../message/GroupChatRank.vue';
	import messageApi from '@/api/message';
	import ServerApi from '@/api/server';
	import UserApi from '@/api/user';
	import VantList from '@/components/VantList';

	export default {
		name: 'GroupChatUser',
		data() {
			return {
				active: 0,
				loveData: [{
					title: '部门成员'
				}, {
					title: '爱心排名'
				}],
				groupId: '',
				joinCode: '',
				grouplist: [],
				groupTitle: '',
				groupVerify: 0,
				groupTotalUser: '',
				isShowJoinPopup: false,
				joinInputIsFocus: false,

				loading: true,
				finished: false,
				isEmpty: false,
				paramsPage: 1,
				paramsLimit: 20,
				district_id: '',
				type: ''
			};
		},
		created() {
			this.groupId = this.$route.params.id;
			this.district_id = this.$route.params.district_id
			this.type = this.$route.params.type
			if(this.type){
				this.loveData = [{title: '部门成员'}]
			}
			this.onLoad()
		},
		watch: {
			joinCode() {
				if (this.joinCode.length >= 4) {
					this.onJoinGroup();
					this.joinCode = this.joinCode.substring(0, 4);
				}
			}
		},
		methods: {
			testuserName(value) {
				return value.replace(value.substr(1, 1), "*");
			},
			onLoad() {
				this.loading = true;
				
				let params = {}
				
				if(this.type){
					params = {
						page: this.paramsPage,
						limit: this.paramsLimit,
						group_id: this.groupId,
					};
				}else{
					params = {
						page: this.paramsPage,
						limit: this.paramsLimit,
						group_id: this.groupId,
						district_id: this.district_id
					};
				}
				
				messageApi.chatMembers(params)
					.then(result => {
						this.paramsPage++;

						this.grouplist = this.grouplist.concat(result.data.list);
						this.groupTitle = result.data.header.title;
						// this.groupVerify = result.data.header.verify;
						this.groupTotalUser = result.data.header.total_user;


						this.loading = false;

						if (this.grouplist.length <= 0) {
							this.isEmpty = true;
						}

						if (this.grouplist.length >= result.data.total) {
							this.finished = true;
						}

					}).catch(error => {
						console.log(error);
					});
			},
			onClick(key) {
				this.active = key;
			},
		},
		components: {
			WTab,
			GroupChatRank,
			VantList
		}
	};
</script>

<style lang="less" scoped>
	.color {
		background: #f2f2f2 !important;
	}

	.group-chat-user {
		background-color: #fff;

		.head-top {
			padding: 9px 0 7px;
			text-align: center;
			background-color: #fff;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

			.head-top-title {
				font-size: 18px;
				line-height: 24px;
				color: #333;
			}

			.head-top-text {
				font-size: 12px;
				line-height: 16px;
				color: #aaa;
			}
		}

		.head-nav {
			padding-left: 10px;
			padding-top: 13px;
		}

		.head {
			.middle-text {
				font-size: 16px;
				color: #666;
				text-align: center;
				margin-top: 25%;
			}

			.head-middle {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				padding: 0 10px;

				.head-middle-item {
					width: 172px;
					min-width: 172px;
					height: 70px;
					background: #f2f2f2;
					border-radius: 6px;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					padding: 10px;
					margin-top: 10px;
					box-sizing: border-box;

					.item-picture {
						width: 51px;
						min-width: 51px;
						height: 50px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 6px;
							object-fit: cover;
						}

						padding-right: 10px;
					}

					.item-right {
						.item-right-title {
							font-weight: 700;
							font-size: 16px;
							line-height: 20px;
							color: #333;
						}

						.item-right-text {
							font-size: 12px;
							line-height: 20px;
							color: #666;
							margin-top: 5px;
						}
					}
				}
			}

			.heda-conner {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: wrap;

				.head-conner-item {
					margin-top: 20px;
					width: 20%;
					text-align: center;

					.conner-item-picture {
						width: 51px;
						min-width: 51px;
						height: 50px;
						margin-left: 50%;
						transform: translateX(-50%);

						img {
							width: 100%;
							height: 100%;
							border-radius: 6px;
							object-fit: cover;
						}
					}

					.conner-item-text {
						font-size: 12px;
						line-height: 20px;
						color: #333;
						margin-top: 3px;
					}
				}
			}

			.footer {
				position: fixed;
				width: 100%;
				bottom: 20px;

				.footer-btn {
					padding: 20px;
				}

				.van-button__text {
					font-size: 18px;
					line-height: 26px;
					color: #fff;
				}
			}
		}

		/deep/.van-overlay {
			background-color: rgba(0, 0, 0, 0.5);
		}

		.van-popup--center {
			border-radius: 8px;
		}

		.join-popup-wrap {
			height: 170px;
			background-color: transparent;

			.join-popup {
				width: 278px;
				height: 130px;
				background-color: #fff;
				border-radius: 8px;

				.join-popup-title {
					font-size: 14px;
					line-height: 20px;
					text-align: center;
					color: #666;
					padding-top: 20px;
				}

				.join-popup-code {
					display: flex;
					padding: 10px 20px 0;

					@keyframes flicker {
						0% {
							opacity: 0;
						}

						100% {
							opacity: 1;
						}
					}

					.join-popup-code-item {
						width: 40px;
						height: 60px;
						background-color: #ddd;
						line-height: 60px;
						text-align: center;
						font-size: 40px;
						border-radius: 8px;
						margin-left: 26px;
						color: #000;
						position: relative;

						&:before {
							content: '';
							position: absolute;
							top: 50%;
							left: -18px;
							width: 10px;
							height: 2px;
							margin-top: -1px;
							background-color: #d9d9d9;
						}

						&:first-child {
							margin-left: 0;

							&:before {
								display: none;
							}
						}

						&.join-popup-code-item-focus:after {
							content: '';
							position: absolute;
							left: 50%;
							top: 50%;
							width: 2px;
							height: 30px;
							margin-top: -15px;
							background-color: #3377ff;
							animation: flicker 1s infinite;
						}
					}
				}

				.join-input {
					position: fixed;
					left: -1000px;
					top: -1000px;
				}
			}

			.join-close {
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -15px;
				font-size: 30px;
				color: #d9d9d9;
			}
		}
	}
</style>