<template>
	<div class="about">
		<w-navTab :titleText="titleText"></w-navTab>
		<div class="container">
			<div class="head-nav">
				<scroll-tab class="activity-tab-city" :scroll-x="true" :menu="navList" :active="active" field="title"
					@click="onChange" v-if="districtType() == 1"></scroll-tab>
					
				<scroll-tab class="activity-tab-container" :scroll-x="true" :menu="navList" :active="active" field="title"
					@click="onChange" v-if="districtType() == 2"></scroll-tab>
			</div>
			
			<div class="about-container">
				<w-about-about v-if="active == 0"></w-about-about>
				<w-about-leader v-if="active == 1"></w-about-leader>
				<w-about-dispose v-if="active == 2"></w-about-dispose>
				<W-AboutTalent v-if="active == 3"></W-AboutTalent>
				<w-about-abylaws v-if="active == 4"></w-about-abylaws>
				<w-about-emblem v-if="active == 5"></w-about-emblem>
				<w-aboutSong v-if="active == 6"></w-aboutSong>
			</div>
		</div>
	</div>
</template>

<script>
	
import ScrollTab from '@/components/ScrollTab';
import WAboutAbout from '@/views/news/components/AboutAbout';
import WAboutLeader from '@/views/news/components/AboutLeader';
import WAboutAbylaws from '@/views/news/components/AboutAbylaws';
import WAboutEmblem from '@/views/news/components/AboutEmblem';
import WAboutTalent from '@/views/news/components/AboutTalent';
import WAboutDispose from '@/views/news/components/AboutDispose';
import WAboutSong from '@/views/news/components/AboutSong';

export default {
	name: 'About',
	inject: ['className', 'districtType', 'themeColor'],
	data() {
		return {
			active: 0,
			navList: [
				{
					id: 1,
					title: '协会介绍'
				},
				{
					id: 2,
					title: '领导班子'
				},
				{
					id: 5,
					title: '理事单位'
				},
				{
					id: 107,
					title: '信息公开'
				},
				{
					id: 3,
					title: '协会章程'
				},
				{
					id: 4,
					title: '会徽释义'
				},
				{
					id: 6,
					title: '协会会歌'
				}
			],
			titleText:""
		};
	},
	created() {
		this.titleText = this.navList[this.active].title
	},
	methods: {
		onChange(key) {
			this.active = key;
			this.titleText = this.navList[this.active].title
		}
	},
	components: {
		ScrollTab,
		WAboutAbout,
		WAboutLeader,
		WAboutAbylaws,
		WAboutEmblem,
		WAboutTalent,
		WAboutDispose,
		WAboutSong
	}
};
</script>

<style scoped lang="less">
.about {
	background-color: #fff;
	.container{
		padding: 0 10px 10px 10px;
		box-sizing: border-box;
		
		
		
		.head-nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 18px 0 0 0;
			border-bottom: 1px solid #ddd;
		
			.activity-tab-city {
				margin-bottom: -1px;
				height: 29px;
			
				/deep/.scroll-tab-item {
					padding-top: 0;
					font-size: 18px;
					line-height: 27px;
					padding-bottom: 2px;
			
					&.scroll-tab-item-active:after {
						width: 100%;
						height: 2px;
						background-color: #3377ff;
						bottom: 0;
					}
				}
			}
			
			.activity-tab-container {
				margin-bottom: -1px;
				height: 29px;
			
				/deep/.scroll-tab-item {
					padding-top: 0;
					font-size: 18px;
					line-height: 27px;
					padding-bottom: 2px;
			
					&.scroll-tab-item-active:after {
						width: 100%;
						height: 2px;
						background-color: #ff6969;
						bottom: 0;
					}
				}
			}
		}
		
		.about-container {
			width: 100%;
			margin-top: 15px;
			overflow: hidden;
			box-sizing: border-box;
		}
	}
	
}
</style>
